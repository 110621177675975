import React from 'react'

export function SplashTwo() {
    return (
        <section id="splash-two">
            <div className="container">
                <div className="splash-two-text">
                    HOW YOU LOVE YOURSELF<br />
                    IS HOW YOU TEACH 
                    OTHERS TO LOVE YOU
                    <span className="rupi-kaur"> -RUPI KAUR</span>
                </div>
            </div>
        </section>
    )
}