import React from 'react'
import treniece2 from '../assets/treniece2.png'

export function About() {
    return (
        <section className="normal-scroll" id="about">
            <div className="container">
                <p className="about-header"> Your Journey ... It's About You </p> 
                <div className="about-intro">
                …I am <em><b>“Birthed”</b></em> to helping women overcome obstacles and roadblocks that have set us back 
                from <em><b>“moving forward”</b></em> in our <em><b>Life’s Purpose.</b></em> Your Journey is not a <em><b>“One Size Fits”</b></em>
            &nbsp; but a program individually designed to help meet you <em><b>“Where You Are At”</b></em>.
                </div>
                <div className="about-wrapper">
                    <img className="treniece-img-about" src={treniece2} alt={"Trenience"} />
                    <div className="about-text">
                        <div className="about-bullet-one"><b>WOMEN</b>, <em>let's learn to love ourselves from the inside out past our pain; take charge of our health and wellness.</em></div><br />
                        <div className="about-bullet-two"><b>WOMEN</b>, <em>let's learn to make decisions for your spiritual, mental and physical health where inner healing really begins.</em></div><br />
                        <div className="about-bullet-three"><b>WOMEN</b>, <em>let's discover together how you can have a better relationship with yourself.</em></div><br />
                    <div className="about-bio">I am a Holistic Health and Wellness Coach who empowers women to become our authentic selves, the very best versions
                        of ourselves, making healthier lifestyle choices without judgment. I provide coaching employing proven tactics on
                        increasing energy and vitality, improving sleeping habits, losing belly fat, controlling sugar cravings, releasing weight
                        and thinking with greater clarity.</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

