import React, { useState } from 'react'
import { FaRegWindowClose } from "react-icons/fa";

import Modal from 'react-modal'

export function SplashOne() {

    const [isOpen, openModal] = useState(false)
    const [message, setMessage] = useState('')

    Modal.setAppElement('#root');

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    })

    const setValue = (key, value) => {
        setFormData({ ...formData, [key]: value })
    }

    const handleSubmit2 = (e) => {
        e.preventDefault();
        const data = {
            from: `${formData.name} ${formData.email}`,
            to: "yourjourneymailgun@gmail.com",
            subject: 'Your Journey',
            text: formData.message
        };
        window.open(`mailto:treniecehealthcoach@gmail.com?subject=${data.subject}&body=${data.text}`);
    }
    
    const handleModalClose = () => {
        handleReset();
        setMessage('')
        openModal(false)
        document.body.style.overflow = 'initial';
    }

    const handleOpenModal = () => {
        openModal(true);
        document.body.style.overflow = 'hidden';
    }

    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            message: ''
        })
    }

    return (
        <section id="splash-one">
            <div className="hero">
                <h5 className="your-journey-title">YOUR JOURNEY:</h5>
                <h5 className="its-about-you">IT'S ABOUT YOU</h5>
                <p className="sub-heading">
                    DO SOMETHING TODAY THAT YOUR<br /> FUTURE
                            SELF WILL THANK YOU FOR
                        </p>
                <button onClick={() => handleOpenModal()} className="cta-btn"
                >LEARN MORE</button>
                <Modal isOpen={isOpen}
                    closeTimeoutMS={1000}
                    style={{
                        overlay: {
                            zIndex: 999999999
                        },
                        content: {
                            position: "static",
                            top: "auto",
                            right: "auto",
                            bottom: "auto",
                            left: "auto",
                            margin: window.innerWidth > 500 ? "5% 10% 8% 10%" : "15% 10% 8% 10%"},
                        }
                    }
                >
                    <div className="popUp">
                        <div className="popUp-text">
                            Reach Out To Learn More
                             <br></br><br></br>
                            <div className="contact-form">
                                <form onSubmit={handleSubmit2}>
                                    <label>NAME: <input type="text" value={formData.name} name="name" required
                                        onChange={(e) => setValue("name", e.target.value)}
                                    /></label><br></br><br></br>
                                    <label>EMAIL: <input type="email" value={formData.email} name="name" required
                                        onChange={(e) => setValue("email", e.target.value)}
                                    /></label><br></br><br></br>
                                    <label>MESSAGE: <br></br><br></br>
                                        <textarea name="message" cols={34} rows={10} value={formData.message} required
                                            onChange={(e) => setValue("message", e.target.value)}
                                        /></label><br></br><br></br>
                                    {message.length === 0 ? 
                                    <input className="cta-btn" id="email-submit-btn" type="submit" value="SUBMIT" />
                                    : 
                                    <div className="message-sent">{message}</div>
                                    }
                                </form>
                            </div>
                        </div>
                        <FaRegWindowClose size={30} className="close-modal-btn" onClick={handleModalClose} />
                    </div>
                </Modal>
            </div>
        </section>
    )
}