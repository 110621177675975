import React from 'react';
import treniece from '../assets/treniece.png';
import BHCSeal from '../assets/BHCSeal.jpg';
import BLCSeal from '../assets/BLCSeal.jpg';
import HCASeal from '../assets/HCASeal.png';
import HCISeal from '../assets/HCI-Seal_CPT.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

export function Contact() {

    const openEmail = () => {
        window.open('mailto:treniecehealthcoach@gmail.com?subject=Your Journey: It\'s About You&body=Hi, I\'d like to learn more!');
    }
    // Treniece Campbell
    // Health and Life Coach
    // treniecehealthcoach@gmail.com
    // 248 225-7812
    return (
        <section className="normal-scroll-contact" id="contact">
            <div className="container">
                <span>CONTACT</span>
                <div className="contact-grid">
                    <div className="grid-column-1">
                        <p className="phone-link">
                            <a href="tel:+12482257812" className="phone-a"> <FontAwesomeIcon icon={faPhone} /> (248) 225-7812</a></p>
                        <p onClick={openEmail} className="email-link">
                            <span><FontAwesomeIcon icon={faEnvelope} />   treniecehealthcoach@gmail.com </span></p>
                    </div>
                    <img className="contact-img-circle" src={treniece} alt={"Trenience"} />
                </div>

                <div className="bottom-row">
                    <img className="bhc-seal" alt="health coach seal" src={BHCSeal}></img>
                    <img className="blc-seal" alt="life coach seal" src={BLCSeal}></img>
                    {/* <img className="hca-seal" alt="health-coach-alliance" src={HCASeal}></img> */}
                    <img className="hci-seal" alt="certified personal trainer" src={HCISeal}></img>
                </div>

            </div>
        </section>
    )
}
