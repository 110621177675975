import React from 'react'

import { SplashOne } from './SplashOne';
import { About } from './About';
import { SplashTwo } from './SplashTwo';
import { Testimonials } from './Testimonials/NewTestimonials';
import { SplashThree } from './SplashThree';
import { Contact } from './Contact';
import { CompanionSites } from './CompanionSites';

export function Home() {

	return (
		<>
			<SplashOne />
			<About />
			<SplashTwo />
			<Testimonials />
			<SplashThree />
			<Contact />
			<CompanionSites />
			<div className="app-bottom"></div>
		</>
	)
}
