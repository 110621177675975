import React from 'react';
import testimonials from './testimonials.json';
import {Carousel} from '3d-react-carousal';

import './NewTestimonials.css';

export function Testimonials() {

    let slides = []
    testimonials.map(testimonial => {
        return slides.push(
            <div className={testimonial.class}>
                {testimonial.imageUrl.length > 0 ? 
                <img className="testimonial-img" src={testimonial.imageUrl} alt="author of testimonial"></img> 
                : null}
                <p className="testimonial-body-text">{testimonial.bodyText}</p>
                <span className="testimonial-author">-{testimonial.author}</span>
            </div>
        )
    })

    return(
        <section id="testimonial" className="normal-scroll">
            <span className="testimonials-title">TESTIMONIALS</span>
            <Carousel slides={slides} autoplay={false}/>
        </section>
    )
}
  