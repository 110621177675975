import React from 'react';

export function Disclaimer(){

    return(
        <div id="disclaimer" className="disclaimer-wrapper">
            <h4>Medical Disclaimer:</h4>
            <p className="disclaimer">        
The content on this website is provided to you for informational and self-empowerment purposes ONLY.  This content is not intended to prevent, diagnose, treat or cure any disease or medical conditions. 
I am not a medical doctor, registered dietician, nutritionist or hold any medical license.  I make no claims to any specialized medical training nor do I dispense medical advice or prescriptions.
Please consult your doctor or licensed practitioner if you have any questions regarding the suggestions made in this content.
            </p>
        </div>
    )
}