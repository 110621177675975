import React from 'react';
import './styles/App.css';
import './styles/About.css';
import './styles/Testimonial.css';
import './styles/CompanionSites.css';
import './styles/Contact.css';
import { Home } from './components/Home';
import { SidebarComp } from './components/Sidebar';
import { SidebarNew } from './components/Sidebar/SidebarNew';
import { createMedia } from "@artsy/fresnel";
import { Header } from './components/Header';

function App() {
	const { MediaContextProvider, Media } = createMedia({
		breakpoints: {
			sm: 0,
			mm: 600,
			md: 768,
			lg: 1024,
			xl: 1192,
		},
	})
	return (
		<>
			<MediaContextProvider>
			<Media greaterThanOrEqual="mm">
					<Header class="head-test-1"/>
				</Media>
				<Media lessThan="mm">
					<SidebarNew/>
				</Media>
					<Home />
			</MediaContextProvider>
		</>
	)
}

export default App;
