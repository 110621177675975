import React from 'react'

export function SplashThree() {
    return (
        <section id="splash-three">
            <div className="container">
                <div className="splash-three-text">
                    "ENCOURAGE YOURSELF, BELIEVE IN YOURSELF, AND LOVE YOURSELF. NEVER DOUBT WHO YOU ARE."<br></br><br></br>
                    - STEPHANIE LAHART
                </div>
            </div>
        </section>
    )
}