import React from 'react'

import { Disclaimer } from './Disclaimer';

export function CompanionSites() {
    return(
        <section className="normal-scroll-contact companion-site" id="stores">
            <Disclaimer></Disclaimer>
        </section>
    )
}