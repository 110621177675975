import React, {useState} from "react";
import './Dropdown.css';
import { FaRegWindowClose } from "react-icons/fa";
import Modal from 'react-modal'

export function Dropdown({page, title, options}) {

    const [selectedItem, setSelectedItem] = useState({
        contnet: '',
        name: '',
        key: '',
        selected: false,
    })

    const [isModalOpen, openModal] = useState(false)
    Modal.setAppElement('#root');

    const [isOpen, setOpen] = useState(false);

    const selectItem = (item) => {

        switch(item.content){
            case 'link':
                window.location.href = item.href;
                break;
            case 'no-behavior':
                return;
            default: 
                setSelectedItem({
                    content: item.content,
                    name: item.name,
                    key: item.key,
                    selected: true,
                })
                openModal(true);
                document.body.style.overflow = 'hidden';
                setOpen(false);
            break;
        }
    }

    const handleModalClose = () => {
        setOpen(false)
        openModal(false);
        document.body.style.overflow = 'initial';
    }

    const showDropDown = () => {
        if(options.length > 0){setOpen(true)}
    }

    const hideDropdDown = () => {
        if(options.length > 0){setOpen(false)}
    }

  return (
    <div className="dd-wrapper" 
        onMouseEnter={() => showDropDown()}
        onMouseLeave={() => hideDropdDown()}
    >
        <a href={page?.location?.length > 0 ? page.location : null} className="nav-btn-href">
            <button type="button" className="dd-header" onClick={() => hideDropdDown()}>
                <div className="dd-header-title">{title}</div>
            </button>
        </a>
      <div role="list" className={'dd-list' + (!isOpen ? ' inactive': '') + (page.name === 'STORES' ? ' stores-list' : '')}  >
            {options?.map((item, index) => {
            return(
                <div key={index} className="stores-dropdown">
                    <div type="button"
                        className={item.class}
                        disabled={item.disable}
                        onClick={() => selectItem(item)}
                        >
                        {item.name}
                    </div>
                    <div className={"nested-nav-wrapper" + (page.name === 'STORES' ? ' stores-internal' : '')}>
                    {item.options?.map((nestedItem, index) => {
                            return(<button    
                                className="nested-nav store-lvl" key={index}
                                onClick={() => window.open(nestedItem.link)}
                            >
                                {nestedItem.name}
                            </button>)
                        })
                        }
                    </div>
                </div>
            )}
            )}
      </div>
      <Modal isOpen={isModalOpen}
            closeTimeoutMS={500}
            style={{
            overlay: {
                zIndex: 999999999999,
                overflow: 'scroll',
            },
            content: {
                backgroundColor: '#e6eff9',
                overflow: 'scroll',
                position: "static",
                top: "auto",
                right: "auto",
                bottom: "auto",
                left: "auto",
                margin: window.innerWidth > 500 ? "5% 10% 8% 10%" : "15% 10% 8% 10%"},
            }}>
            <div>
            <FaRegWindowClose size={30} className="close-modal-btn dropdown-item" onClick={handleModalClose} />
                <h2 className="modal-title">{selectedItem.name}</h2>
                    <p className="display-linebreak">{selectedItem.content}</p>
                </div>
        </Modal>
    </div>
  );
}

