import React from 'react'
import yj from '../assets/YJv2.png'
import { Dropdown } from './Dropdown/Dropdown';

export function Header() {

    const pages = [
        {
            id: 0,
            key: 'navTitle',
            location: '#splash-one',
            name: 'HOME',
            selected: false,
            options: [
                {
                    class: 'dd-list-item',
                    content: 'To empower women to be their authentic self while making healthier life style choices.',
                    name: 'My Mission',
                    selected: false,
                    key: 'navOption',
                },
                {
                    class: 'dd-list-item',
                    content: 'To provide a safe space for you to be the beautiful best version of yourself without perfection and validation of other people’s opinions.',
                    name: 'My Purpose',
                    selected: false,
                    key: 'navOption',
                },
                {
                    class: 'dd-list-item',
                    content: 'I offer one to one health and wellness coaching.  All my coaching is done virtually. I coach women to have a lasting change. I coach women to move past the obstacles that have kept them stuck. I empower women to become in charge of their own well-being, as I love my clients right where they are in their journey. How can I help you be the best version of yourself? Please click the “Learn More” button and schedule a free consultation with me today.',
                    name: 'My Services',
                    selected: false,
                    key: 'navOption',
                }
            ]
        },
        {
            id: 1,
            key: 'navTitle',
            location: '#about',
            name: 'ABOUT',
            selected: false,
            options: [
                {
                    class: 'dd-list-item',
                    name: 'Bio',
                    content: 'link',
                    disable: true,
                    href: '#about',
                    selected: false,
                    key: 'navOptions'

                },
                {
                    class: 'dd-list-item',
                    content: 'My name is Treniece Campbell, \n \n I’m a Holistic Health and Wellness Coach, and a Certified Personal Trainer. I received my certifications from the Health Coach Institute.\n \n I took an interest in holistic lifestyle because I was curious and wanted to educate myself more about health and wellness.\n \n I used to struggle with headaches, digestive issue, fatigue, stress, bloating, an enlarged thyroid, inflammation, and sleep issues. I didn’t like the way I was feeling in my body. I knew I needed to make some changes. I decided to take control over my health and well-being. \n \nOnce I chose to start making healthier lifestyle choices, I began to see a difference with my health. I felt more energized. I wasn’t experiencing frequent headaches; my digestive issues were much better; the bloating wasn’t there; my thyroid went back to normal size; I started losing the inflammation in my body; and my sleep pattern improved. \n \nThe stress in my life became more manageable because I was learning how to love myself. This has been a rewarding experience. I no longer need validation or the approval of other people’s opinion about me. Talk about releasing what no longer serves you. \n \nI used to struggle with body image which caused me to look at myself poorly. I found myself doing things not for myself, but to be accepted by other people’s opinion about me. It wasn’t until I decided to begin to love “me” that changed the way I felt about other people’s opinions of how I should look. \n \nAlthough, the journey to a healthier you takes time. Every small step you take is a step closer to the woman you want to be. God has a purpose and plan for your life.',
                    name: 'My Story',
                    selected: false,
                    key: 'navOption',
                },
                {
                    class: 'dd-list-item',
                    content: 'link',
                    href: '#disclaimer',
                    name: 'Disclaimer',
                    selected: false,
                    key: 'navOption'
                }
            ]
        },
        {
            id: 2,
            key: 'navTitle',
            location: '#testimonial',
            name: 'TESTIMONIAL',
            selected: false,
            options: []
        },
        {
            id: 3,
            key: 'navTitle',
            location: '#contact',
            name: 'CONTACT',
            selected: false,
            options: []
        },
        {
            id: 4,
            key: 'navTitle',
            location: '',
            name: 'STORES',
            selected: false,
            options: [
                {
                    class: 'dd-list-item-no-hvr',
                    content: 'no-behavior',
                    disable: true,
                    key: 'navOption',
                    name: 'Supplements',
                    selected: false,
                    options: [
                        {
                            key: 'supplements',           
                            link: 'https://treniececampbell.idlife.com/',
                            name: 'ID Life',
                            selected: false,
                        },
                        {
                            key: 'supplements',
                            link: 'https://osnap.com/Treniece',
                            name: 'O\'Snap',
                            selected: false,
                        },
                        {
                            key: 'supplements',
                            link: 'https://www.mbody360wellness.com/?ls=secret&afmc=3b&utm_campaign=3b&utm_source=leaddyno&utm_medium=affiliate',
                            name: 'MBody 360 Wellness Store',
                            selected: false,                  
                        }
                    ]
                },
                {
                    class: 'dd-list-item-no-hvr',
                    content: 'no-behavior',
                    disable: true,
                    key: 'navOption',
                    name: 'Essential Oils',
                    selected: false,
                    options: [
                        {
                            key: 'essentialOils',
                            link: 'https://www.beyoungth.com/partners/TrenieceCampbell/',
                            name: 'Be Young',
                            selected: false,
                        }
                    ]
                },
                {
                    class: 'dd-list-item-no-hvr',
                    content: 'no-behavior',
                    disable: true,
                    key: 'navOption',
                    name: 'Skin Care',
                    selected: false,
                    options: [
                        {
                            key: 'skinCare',
                            link: 'https://thebodyshopathome-usa.com/TrenieceCampbell',
                            name: 'The Body Shop',
                            selected: false,
                        }
                    ]
                },
            ]
        }
    ]

    return (
        <div className="header">
            <div className="nav">
            {pages.map((page,key) => (
                <Dropdown
                    key={key}
                    page={page}
                    title={page.name}
                    options={page.options}/>
                ))
            }
            </div>
            <img className="header-logo" src={yj} alt="your-journey logo" />
        </div>
    )
}
